import { ADD_TO_CART, CHANGE_CART_VENUE, REMOVE_FROM_CART, UPDATE_ITEM_QUANTITY } from "../Actions/cartActions";

const initialState = {
    venueID: null,
    venueName: null,
    items: [],
    total: 0,
};

const cartReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_CART_VENUE:
            return {
                ...state,
                venueID: action.payload.id,
                venueName: action.payload.name,
            };
            case ADD_TO_CART:
            const updatedTotalAdd = state.total + action.payload.price * action.payload.quantity;
            const existingItemIndex = state.items.findIndex(item => item.id === action.payload.id);

            if (existingItemIndex !== -1) {
                // Item already exists in the cart, update the quantity
                const updatedItem = {
                    ...state.items[existingItemIndex],
                    quantity: state.items[existingItemIndex].quantity + action.payload.quantity
                };

                return {
                    ...state,
                    total: updatedTotalAdd,
                    items: [
                        ...state.items.slice(0, existingItemIndex),
                        updatedItem,
                        ...state.items.slice(existingItemIndex + 1)
                    ]
                };
            } 
            // Item doesn't exist in the cart or cart is empty, add the new item
            return {
                ...state,
                total: updatedTotalAdd,
                items: [...state.items, action.payload],
            };

        case UPDATE_ITEM_QUANTITY:
            // To compute the updated total, we'll first revert the cost of the original quantity, then add the new cost.
            const itemToUpdate = state.items.find(item => item.id === action.payload.itemId);
            const updatedTotalUpdate = state.total - (itemToUpdate.price * itemToUpdate.quantity) + (action.payload.quantity * itemToUpdate.price);

            const updatedItems = state.items.map(item => 
                item.id === action.payload.itemId ? 
                { ...item, quantity: action.payload.quantity } : 
                item
            );
            
            return {
                ...state,
                total: updatedTotalUpdate,
                items: updatedItems
            };

        case REMOVE_FROM_CART:
            const itemToRemove = state.items.find(item => item.id === action.payload);
            const updatedTotalRemove = state.total - (itemToRemove.price * itemToRemove.quantity);

            return {
                ...state,
                total: updatedTotalRemove,
                items: state.items.filter(item => item.id !== action.payload)
            };


        default:
             return state
    }
};

export default cartReducer
import React, { useState, useEffect} from "react";
import { Link, useLocation } from "react-router-dom";

const MenuVerticalCellView = ({ item }) => {
    const [dimension, setDimension] = useState(0);
    const location = useLocation();

  useEffect(() => {
    // Function to set the dimensions
    const setDimensions = () => {
      const width = ((window.innerWidth-75) * 0.5); // Adjust this factor based on your layout
      setDimension(width);
    };

    // Initial setting
    setDimensions();

    // Add event listener
    window.addEventListener("resize", setDimensions);

    // Cleanup
    return () => {
      window.removeEventListener("resize", setDimensions);
    };
  }, []);
  const itemContainerStyle = {
    display: "flex",
    width: `${dimension}px`,
    flexDirection: "column",
    alignItems: "center",
    margin: "0px 0px",
    justifyContent: "flex-start",
    padding: "0px 0px",
  };

  const menuItemImageStyle = {
    width: `${dimension}px`,
    height: `${dimension}px`,
    borderRadius: "8px",
    alignSelf: "center",
    margin: "0px",
    objectFit: "cover",
  };

  const textStyle = {
    fontSize: "15px",
    fontWeight: "450",
    textAlign: "left",
    margin: "0px",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: "2",
    lineClamp: "2",
    WebkitBoxOrient: "vertical",
  };

  const descriptionStyle = {
    fontSize: "12px",
    fontWeight: "400",
    color: "gray",
    flexGrow: "0",
    textAlign: "left",
    margin: "0px",
  };

  const menuItemDescriptionStyle = {
    flex: "1",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: `${dimension}px`,
    padding: "10px 0px 10px 0px",
    boxSizing: "border-box",
    margin: "0px",
  };

  const menuItemPriceStyle = {
    fontSize: "12px",
    fontWeight: "500",
    flexAlign: "bottom",
    textAlign: "left",
    flexGrow: "1",
    margin: "0px",
    paddingTop: "5px",
  };

  return (
    <Link className="plainLink" to={`${location.pathname}/item/${item.id}`}>
    <div style={itemContainerStyle}>
    {item.imageURL != null &&
    <img src={item.imageURL} style={menuItemImageStyle} alt="menuitem" />}
      <div style={menuItemDescriptionStyle}>
        <p style={textStyle}>{item.name}</p>
        <p className="truncate-text" style={descriptionStyle}>{item.description}</p>
      <p style={menuItemPriceStyle}>{`R ${item.price}`}</p>
      </div>
    </div>
    </Link>
  );
};

export default MenuVerticalCellView;
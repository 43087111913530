import { signInWithPhoneNumber, signOut } from "firebase/auth";
import { authInstance, firestoreInstance } from "../index";
import { doc, getDoc } from "firebase/firestore";
import { setUser } from "../Redux/Actions/authActions";

const ERROR_MESSAGES = {
    "auth/invalid-phone-number": "The phone number format is incorrect. Please check and try again.",
    "auth/missing-phone-number": "Please provide your phone number.",
    "auth/quota-exceeded": "We've sent too many codes. Please try again later.",
    "auth/user-disabled": "This user has been disabled. Please contact support.",
    "auth/operation-not-allowed": "Phone verification is not available right now. Please contact support.",
    "default": "An error occurred. Please try again."
};

class AuthService {
    
        async sendVerificationCode(number, recaptchaVerifier) {
                    const confirmationResult = await signInWithPhoneNumber(authInstance, number, recaptchaVerifier)
                    .catch((error) => {
                        const errorMessage = ERROR_MESSAGES[error.code] || ERROR_MESSAGES["default"];
                        return Promise.reject(new Error(errorMessage));
                    });
                        console.log(confirmationResult);
                        return confirmationResult
        }
        
        
    
        async signInWithCode(code, confirmationResult, dispatch) {
            try {
                console.log(confirmationResult);
                const result = await confirmationResult.confirm(code);
                const snapshot = await getDoc(doc(firestoreInstance, "Users/"+result.user.uid));
                const userData = snapshot.data();
                dispatch(setUser(userData));
                return "Success";
            } catch (error) {
                console.log(error)
                throw error;
            }
        }
    
        async logout() {
            try {
                await signOut(authInstance);
                return true;  // Successfully logged out
            } catch (error) {
                console.log(error)
                throw error;
            }
        }
    }
    
    export default AuthService;
export const CHANGE_CART_VENUE = "CHANGE_CART_VENUE";
export const ADD_TO_CART = "ADD_TO_CART";
export const UPDATE_ITEM_QUANTITY = "UPDATE_ITEM_QUANTITY";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";

const changeCartVenue = (venue) => ({
        type: CHANGE_CART_VENUE,
        payload: {id: venue.id, name: venue.name},
});

const addToCart = (item) => ({
        type: ADD_TO_CART,
        payload: item,
});

const updateItemQuantity = (itemId, quantity) => ({
        type: UPDATE_ITEM_QUANTITY,
        payload: { itemId, quantity },
});

const removeFromCart = (itemId) => ({
        type: REMOVE_FROM_CART,
        payload: itemId,
});


export { changeCartVenue, addToCart, updateItemQuantity, removeFromCart };
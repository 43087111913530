import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import store from './Redux/store';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore} from 'firebase/firestore'
import { HelmetProvider } from 'react-helmet-async';

const firebaseConfig = {
  apiKey: "AIzaSyDyTZtB0wTn8njGSydnVjNrIg31LXJYy6Y",
  authDomain: "crwd-8bc95.firebaseapp.com",
  projectId: "crwd-8bc95",
  storageBucket: "crwd-8bc95.appspot.com",
  messagingSenderId: "1021005079954",
  appId: "1:1021005079954:web:27cc2d82d8cff6cf888d4b",
  measurementId: "G-QRC6PDM08P"
};

const app = initializeApp(firebaseConfig);
const helmetContext = {};
export const authInstance = getAuth(app);
export const firestoreInstance = getFirestore(app);
authInstance.useDeviceLanguage();

let persistor = persistStore(store)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null} >
        <HelmetProvider context={helmetContext}>
    <App />
    </HelmetProvider>
    </PersistGate>
    </Provider>
  </React.StrictMode>
);

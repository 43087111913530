import { firestoreInstance } from "../index";
import { collection, doc, getDocs, query, where } from "firebase/firestore";
import { setMenuItems, setVenue, setVenueCloseTime, setVenueDistance, setVenueLoading, setVenueOpen } from "../Redux/Actions/venueActions";

class VenueService {

    async fetchVenueID(path, dispatch) {
        dispatch(setVenueLoading(true))
        try {
            const venuesRef = collection(firestoreInstance, "Venues");
            const lowercasedPath = path.toLowerCase();
            const q = query(venuesRef, where("path", "==", lowercasedPath));
            const snapshot = await getDocs(q);
            if (!snapshot.empty) {
                const venue = snapshot.docs[0].data();
                dispatch(setVenue(venue))
                await this.fetchMenu(venue.id, dispatch);
                const result = checkOpenStatus(venue.operatingHours);
                dispatch(setVenueLoading(false))
                dispatch(setVenueOpen(result.isOpen))
                dispatch(setVenueCloseTime(result.closeTime))
            }else{
                dispatch(setVenue({}))
                console.log("No Venue Found")
                dispatch(setVenueLoading(false))
            }
        } catch (error) {
            console.log(error)
            throw error;
        }
    }

    async fetchMenu(venueID, dispatch) {
        try {
            const venueRef = doc(firestoreInstance, "Venues", venueID);
            const menuRef = collection(venueRef, "Menu");
            const menuSnapshot = await getDocs(menuRef);
            if (!menuSnapshot.empty) {
                const config = menuSnapshot.docs.find(doc => doc.id === "menuConfig").data();
                const configCategories = config.categories;
                const menuItems = menuSnapshot.docs
                .filter(doc => doc.id !== "menuConfig")
                .map(doc => ({id: doc.id, ...doc.data()}));
                const menuItemsById = menuItems
                .reduce((acc, item) => {
                    acc[item.id] = item
                    return acc
                }, {})

                const organizedMenu = configCategories.reduce((acc, categoryMap) => {
                    const categoryName = categoryMap.name;
                    const menuItemIDs = categoryMap.menuItemIDs

                    acc[categoryName] = menuItemIDs.map(id => menuItemsById[id]).filter(Boolean);
                    return acc 
                }, {})
             // Do something with the menu items
             console.log(organizedMenu)
             dispatch(setMenuItems(organizedMenu));
            } else {
                 console.log("No Menu Items Found");
            }
        } catch (error) {
            console.log(error)
            throw error;
        }
    }

    async checkDistance(lat1, lng1, lat2, lng2, dispatch) {
            function toRad(x) {
              return (x * Math.PI) / 180;
            }
          
            const R =  6371; // Radius of Earth in kilometers
            const dLat = toRad(lat2 - lat1);
            const dLon = toRad(lng2 - lng1);
            const latt1 = toRad(lat1);
            const latt2 = toRad(lat2);
          
            const a =
              Math.sin(dLat / 2) * Math.sin(dLat / 2) +
              Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(latt1) * Math.cos(latt2);
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            const distance = (R * c)
            const string = `${distance} KM`
            const payload = {distanceInt: distance, distanceString: string}  
            dispatch(setVenueDistance(payload))
            console.log(payload)
    }
    
    }

    function weekdayHours(operatingHours) {
        const now = new Date();
        const weekday = now.getDay();// Assuming you have a checkWeekDay function
        const currentTime = getCurrentTimeInt(); // Assuming you have a currentTimeInt function
        let currentDay;
      
        if (weekday === 1) {
          currentDay = currentTime < closesInAm(operatingHours.saturday) ? operatingHours.saturday : operatingHours.sunday;
        } else if (weekday === 2) {
          currentDay = currentTime < closesInAm(operatingHours.sunday) ? operatingHours.sunday : operatingHours.monday;
        } else if (weekday === 3) {
          currentDay = currentTime < closesInAm(operatingHours.monday) ? operatingHours.monday : operatingHours.tuesday;
        } else if (weekday === 4) {
          currentDay = currentTime < closesInAm(operatingHours.tuesday) ? operatingHours.tuesday : operatingHours.wednesday;
        } else if (weekday === 5) {
          currentDay = currentTime < closesInAm(operatingHours.wednesday) ? operatingHours.wednesday : operatingHours.thursday;
        } else if (weekday === 6) {
          currentDay = currentTime < closesInAm(operatingHours.thursday) ? operatingHours.thursday : operatingHours.friday;
        } else if (weekday === 7) {
          currentDay = currentTime < closesInAm(operatingHours.friday) ? operatingHours.friday : operatingHours.saturday;
        }
      
        return currentDay;
      }

      function closesInAm(hours) {
        if (hours.open) {
          if ((hours.openInt || 0) - (hours.closeInt || 0) > 0) {
            return hours.closeInt || 0;
          } else if (hours.open2) {
            return (hours.openInt2 || 0) - (hours.closeInt2 || 0) > 0 ? hours.closeInt || 0 : 0;
          } else {
            return 0;
          }
        } else {
          return 0;
        }
      }

    function getCurrentTimeInt() {
        const date = new Date();
        const hourComponents = date.getHours();
        const minuteComponents = date.getMinutes();
        const hourHundreds = hourComponents * 100;
        return hourHundreds + minuteComponents;
      }

      function checkOpenStatus(operatingHours) {
        const hours = weekdayHours(operatingHours)
        let isOpen = false
        const currentTimeInt = getCurrentTimeInt()
        const closeInt = (hours.closeInt || 0) < (hours.openInt || 0) ? (hours.closeInt || 0) + 2400 : hours.closeInt || 0;
        const closeInt2 = (hours.closeInt2 || 0) < (hours.openInt2 || 0) ? (hours.closeInt2 || 0) + 2400 : hours.closeInt2 || 0;
      
        if (hours.open && hours.open2) {
          if (hours.openInt <= currentTimeInt && closeInt > currentTimeInt) {
            isOpen = true;
           // return closeInt - 100 < currentTimeInt ? 'closingSoon' : 'open';
          } else if (hours.openInt2 <= currentTimeInt && closeInt2 > currentTimeInt) {
            isOpen = true;
           // return closeInt2 - 100 < currentTimeInt ? 'closingSoon' : 'open';
          } else if (hours.openInt2 > currentTimeInt && hours.closeInt2 > currentTimeInt && hours.openInt2 > hours.closeInt2) {
            isOpen = true;
           // return (hours.closeInt2 - 100 < currentTimeInt) ? 'closingSoon' : 'open';
          } else if ((hours.openInt - 100) < currentTimeInt && closeInt > currentTimeInt) {
            isOpen = false;
           // return 'openingSoon';
          } else if ((hours.openInt2 - 100) < currentTimeInt && closeInt2 > currentTimeInt) {
            isOpen = false;
           // return 'openingSoon';
          } else {
            isOpen = false;
           // return 'closed';
          }
        } else if (hours.open) {
          if (hours.openInt <= currentTimeInt && closeInt > currentTimeInt) {
            isOpen = true;
          } else {
            isOpen = false;
          }
        } else {
            isOpen = false;
        }
        return {isOpen: isOpen, closeTime: hours.closeTime}
      }
    
    export default VenueService;
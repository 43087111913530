import { CHANGE_ADDRESS, SET_COUNTRY_CODE, SET_GRID_LAYOUT, SET_IS_DELIVERY, SET_USER } from "../Actions/authActions";

const initialState = {
    selectedAddress: null,
    isSignedIn: false,
    user: null,
    isGridLayout: false,
    isDelivery: true,
    countryCode: "+27",
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_ADDRESS:
            return {
                ...state,
                selectedAddress: action.payload,
            };
        case SET_USER:
            return {
                ...state,
                user: action.payload,
            };
        case SET_GRID_LAYOUT:
            return {
                ...state,
                isGridLayout: action.payload,
            };
        case SET_IS_DELIVERY:
            return {
                ...state,
                isDelivery: action.payload,
            };
        case SET_COUNTRY_CODE:
            return {
                ...state,
                countryCode: action.payload,
            };
        default:
             return state
    }
};

export default authReducer
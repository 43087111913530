import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import StepperCounter from '../Helpers/Components/StepperCounter';
import { removeFromCart, updateItemQuantity } from '../../Redux/Actions/cartActions';

const ReviewOrder = () => {
  const state = useSelector((state) => state.cart)
  const dispatch = useDispatch();
  const items = state.items;
  const navigate = useNavigate();

    const incrementQuantity = (itemID, index) => {
      dispatch(updateItemQuantity(itemID, items[index].quantity + 1))
    }

    const decrementQuantity = (itemID, index) => {
      if (items[index].quantity > 1) {
        dispatch(updateItemQuantity(itemID, items[index].quantity - 1))
      }else{
        dispatch(removeFromCart(itemID))
      }
    }

    const continueOrder = () => {
        navigate("/auth")
    }

    const goBack = () => {
        navigate(-1)
    }

    useEffect(() => {
      if (items.length === 0) {
        navigate(-1)
      }
    }, [items]);

  const headerStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    margin: "15px 15px"
  }

  const textHeaderStyle = {
    textAlign: "center",
    flexGrow: "1",
    fontSize: "16px",
    fontWeight: "550",
    margin: "0px 20px 0px 0px"
  }

  const buttonContainerStyle = {
    position: "fixed",
    bottom: "20px",
    width: "100%",
    zIndex: "1000"
  }

  const cartItemsContainerStyle = {
    margin: "15px 15px",
    padding: "0px 15px",
    backgroundColor: "#f1f1f1",
    borderRadius: "10px"
  }

  const cartItemCellStyle = {
    display: "flex",
    alignItems: "center",
    textAlign: "left",
    justifyContent: "flex-start",
    padding: "15px 0px",
  }

  return (
    <div style={{alignItems: "center", textAlign: "center"}} className="reviewOrder">
        <div style={headerStyle}>
        <span onClick={goBack} className="material-symbols-rounded" style={{display: "block", textAlign: "left", margin: "0", padding: "0", fontSize: "30px"}}>
chevron_left
</span>
            <h5 style={textHeaderStyle}>Review Order</h5>
            </div>
            <div className="dividerHorizontal"></div>
      <div style={cartItemsContainerStyle}>
        {items.map((item, index) => (
          <div key={index} style={cartItemCellStyle}>
          <div style={{flexGrow: "1"}}>
          <p style={{fontSize: "16px",fontWeight: "550", margin: "0"}}>{item.name}</p>
          <p style={{fontSize: "14px",fontWeight: "500", margin: "0", color: "gray"}}>R {item.price}</p>
          </div>
          <StepperCounter 
          value={item.quantity} 
          onIncrement={() => incrementQuantity(item.id, index)}
          onDecrement={() => decrementQuantity(item.id, index)}
          showBin={true}
          style={{margin: "0px", backgroundColor: "white"}} />
      </div>
          ))}
      </div>
      <div style={{...cartItemsContainerStyle, ...cartItemCellStyle, padding: "15px 15px"}}>
       <p style={{fontSize: "18px",fontWeight: "550", margin: "0", flexGrow: "1"}}>Subtotal</p>
       <p style={{fontSize: "16px",fontWeight: "550", margin: "0"}}>R {state.total}</p>
      </div>
      <div style={buttonContainerStyle}>
      <div className="dividerHorizontal" style={{margin: "0px 0px 15px 0px"}}></div>
      <button className='primaryButton primaryButtonBox' type="button" onClick={continueOrder}>Go to Checkout</button>
      <button onClick={goBack} style={{backgroundColor: "#f1f1f1", color: "black", margin: "10px 0px 0px 0px"}} className='primaryButton primaryButtonBox' type="button">Add Items</button>
      </div>
    </div>
  );
};

export default ReviewOrder;